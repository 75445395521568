import React from 'react';
import { Form, Input } from 'antd';


export default class ContactUs extends React.Component {
    render() {
        return (

            <Form>
                <Form.Item label="Success" hasFeedback validateStatus="success">
                    <Input placeholder="I'm the content" id="success" />
                </Form.Item>
            </Form>

        );
    }
}