import React from 'react';
import './App.css';
import 'antd/dist/antd.css';
import { Layout } from 'antd';

import Navbar from './Global/nav';
import Bottom from './Global/footer';
import Home from './Home/home';
import Technology from './Technology/technology';
import Construction from './Construction/construction';
import Security from './Security/security';
import Market from './Market/market';
import ContactUs from './Contact/contact'
import { BrowserRouter as Router, Route} from "react-router-dom";


function App() {

  return (
    <Router>
      <Layout className="layout" style={{ background: 'white', fontFamily: "Verdana, Geneva, Tahoma, sans-serif", color: "#090909" }}>
        <Navbar />
        <Route exact path="/" component={Home}/>
        <Route exact path="/technology" component={Technology}/>
        <Route exact path="/construction" component={Construction}/>
        <Route exact path="/security" component={Security}/>
        <Route exact path="/market" component={Market}/>
        {/* <Route exact path="/contact" component={ContactUs}/> */}
        <Bottom />
      </Layout>
    </Router>
  );
}

export default App;
