import React from 'react';
import { Layout } from 'antd';
import { Row, Col, Icon } from 'antd';
const { Footer } = Layout;
// const data = [
//     'Racing car sprays burning fuel into crowd.Japanese princess to wed commonerAustralian walks 100km after outback crash.',
// ];



class Bottom extends React.Component {

    render() {
        return (

            <Footer style={{ width: '100%', background: '#090909', position: "relative", bottom: "0", height: "190px", overflow:'hidden' }}>
                <Row gutter={8}>
                    <Col className="gutter-row" span={6} >
                        <dl style={{ color: 'white', height: '100%' }}>
                            <dt style={{ fontWeight: 'bold' }}>JESKGROUP</dt>
                            <dt style={{ margin: '20px 0' }}>Racing car sprays burning fuel into crowd.Japanese princess to wed commonerAustralian</dt>
                            <dt style={{ fontWeight: 'bold' }}>JESKGROUP ©2018</dt>
                        </dl>
                    </Col>
                    <Col className="gutter-row" span={6} >
                        <div style={{ color: 'white' }}>
                            <span style={{ fontWeight: 'bold' }}>QUICK LINKS</span>
                            <ul style={{ padding: '15px' }}>
                                <li>TECHNOLOGY</li>
                                <li>CONSTRUCTION</li>
                                <li>CONTACT US</li>
                            </ul></div>
                    </Col>
                    <Col className="gutter-row" span={6} >
                        <ul style={{ padding: '35px 15px', color:'white' }}>
                            <li>MARKET</li>
                            <li>SECURITY</li>
                        </ul>
                    </Col>
                    <Col className="gutter-row" span={6} >
                        <dl style={{ color: 'white', height: '100%' }}>
                            <dt style={{ fontWeight: 'bold' }}>INFORMATION</dt>
                            <dt style={{ margin: '25px 0' }}><Icon type="mail" theme="filled" />&nbsp;&nbsp;JESKGroup@jesksupport.com</dt>
                            <dt style={{ fontWeight: 'bold' }}><Icon type="phone" theme="filled" />&nbsp;&nbsp;0400000000</dt>
                        </dl>
                    </Col>
                </Row>
            </Footer>
        );
    }
}

export default Bottom;