import React from 'react';
import { Row, Col } from 'antd';
import product1 from './img/1.png';
import product2 from './img/2.png';
import product3 from './img/3.png';
import product4 from './img/4.png';
import product5 from './img/5.png';
import product6 from './img/6.png';

export default class Products extends React.Component {
    render() {
        return (
            <div style={{ padding: '0 50px', textAlign: "center" }}>
                <div style={{ padding: '15px 0', fontWeight: 'bold', fontSize: '20px' }}>RIGHT NOW IN STORE</div>
                <Row gutter={[16,16]}>
                    <Col className="gutter-row" span={6}>
                        <div className="market-product-box">
                            <img className="market-product-img" alt="professional" src={product1} />
                            <div className="market-product-title">PROFESSIONAL</div>
                            <div className="market-product-info">Ullamco consequat mollit tempor velit et nostrud magna. Ad nulla laborum laboris adipisicing reprehenderit est. Dolore enim ut ut in do magna aliquip esse culpa ipsum duis. </div>
                            <div className="market-product-link">View more</div>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div className="market-product-box">
                            <img className="market-product-img" alt="professional" src={product2} />
                            <div className="market-product-title">PROFESSIONAL</div>
                            <div className="market-product-info">Ullamco consequat mollit tempor velit et nostrud magna. Ad nulla laborum laboris adipisicing reprehenderit est. Dolore enim ut ut in do magna aliquip esse culpa ipsum duis. </div>
                            <div className="market-product-link">View more</div>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div className="market-product-box">
                            <img className="market-product-img" alt="professional" src={product3} />
                            <div className="market-product-title">PROFESSIONAL</div>
                            <div className="market-product-info">Ullamco consequat mollit tempor velit et nostrud magna. Ad nulla laborum laboris adipisicing reprehenderit est. Dolore enim ut ut in do magna aliquip esse culpa ipsum duis. </div>
                            <div className="market-product-link">View more</div>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div className="market-product-box">
                            <img className="market-product-img" alt="professional" src={product4} />
                            <div className="market-product-title">PROFESSIONAL</div>
                            <div className="market-product-info">Ullamco consequat mollit tempor velit et nostrud magna. Ad nulla laborum laboris adipisicing reprehenderit est. Dolore enim ut ut in do magna aliquip esse culpa ipsum duis. </div>
                            <div className="market-product-link">View more</div>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div className="market-product-box">
                            <img className="market-product-img" alt="professional" src={product5} />
                            <div className="market-product-title">PROFESSIONAL</div>
                            <div className="market-product-info">Ullamco consequat mollit tempor velit et nostrud magna. Ad nulla laborum laboris adipisicing reprehenderit est. Dolore enim ut ut in do magna aliquip esse culpa ipsum duis. </div>
                            <div className="market-product-link">View more</div>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div className="market-product-box">
                            <img className="market-product-img" alt="professional" src={product6} />
                            <div className="market-product-title">PROFESSIONAL</div>
                            <div className="market-product-info">Ullamco consequat mollit tempor velit et nostrud magna. Ad nulla laborum laboris adipisicing reprehenderit est. Dolore enim ut ut in do magna aliquip esse culpa ipsum duis. </div>
                            <div className="market-product-link">View more</div>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div className="market-product-box">
                            <img className="market-product-img" alt="professional" src={product5} />
                            <div className="market-product-title">PROFESSIONAL</div>
                            <div className="market-product-info">Ullamco consequat mollit tempor velit et nostrud magna. Ad nulla laborum laboris adipisicing reprehenderit est. Dolore enim ut ut in do magna aliquip esse culpa ipsum duis. </div>
                            <div className="market-product-link">View more</div>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div className="market-product-box">
                            <img className="market-product-img" alt="professional" src={product6} />
                            <div className="market-product-title">PROFESSIONAL</div>
                            <div className="market-product-info">Ullamco consequat mollit tempor velit et nostrud magna. Ad nulla laborum laboris adipisicing reprehenderit est. Dolore enim ut ut in do magna aliquip esse culpa ipsum duis. </div>
                            <div className="market-product-link">View more</div>
                        </div>
                    </Col>
                </Row>

            </div>


        );
    }
}