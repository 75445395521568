import React from 'react';
import { Layout } from 'antd';
// import { Row, Col } from 'antd';
import Banner from './banner';
import FourPanel from './panels';
import MailBox from './mail_box';

const { Content } = Layout;

export default class Technology extends React.Component {

    render() {
        return (
            <Content style={{ padding: '50px 5px', backgroundColor:"#F4F4F4" }}>
                <Banner/>
                <br/>
                <br/>
                <FourPanel/>
                <br/>
                <MailBox/>
            </Content>
        );
    }

}