import React from 'react';
import { Icon } from 'antd';
import {Link} from 'react-router-dom'; 
// import Clock from '../utils/clock';


class Banner extends React.PureComponent {
    render() {
        return (
            <div className="construction-banner">
                <div className="construction-bg" >
                    <div className="construction-banner-title" >
                        JESK CONSTRUCTION
                    </div>
                    <div className="construction-banner-slogan">
                        Right people, right service, right choice
                    </div>
                    <div style={{fontSize:"25px", fontWeight:'bold', marginTop:"35px"}} ><Link style={{color:'white'}}> KNOW MORE <Icon type="right"/> </Link></div>
                </div>
            </div>
        );
    }
}

export default Banner;