import React from 'react';
import { Row, Col, Button } from 'antd';

class Mail_Box extends React.Component {

    render() {
        return (

            <div className="email-box" style={{padding:'10px 50px', background:'white'}}>
                <div style={{padding:'5px 0', fontWeight:'bold', fontSize:'20px'}}>CONTACT US</div>
                <Row gutter={8}>
                    <Col className="gutter-row" span={6} >
                        <div className="email-box-box email-box-name" >Name</div>
                    </Col>
                    <Col className="gutter-row" span={6} >
                        <div className="email-box-box email-box-phone">Phone</div>
                    </Col>
                    <Col className="gutter-row" span={12} >
                        <div className="email-box-box email-box-email">Email</div>
                    </Col>
                </Row>
                <div style={{marginTop:'10px'}}>
                    <Row gutter={8}>
                        <Col className="gutter-row" span={24}  >
                            <div className="email-box-box email-box-message" style={{minHeight:'130px'}}>Message</div>
                        </Col>
                    </Row>
                </div>
                <Button style={{backgroundColor:'#115599', color:'white', marginTop:'10px', fontWeight:'bold'}}>
                    Send Message
                </Button>
            </div>
        );
    }


}

export default Mail_Box;