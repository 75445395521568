import React from 'react';
import Banner from './banner';
import FourPanels from './four_panels';
import EmailBox from './email_box';
import { Layout } from 'antd';
const { Content } = Layout;

export default class Home extends React.Component{

    render(){
        return(
          <Content style={{ padding: '50px 50px' }}>
            <Banner />
            <FourPanels />
            <EmailBox />
          </Content>
        );
    }

}