import React from 'react';
import { Icon } from 'antd';
import {Link} from 'react-router-dom'; 
// import Clock from '../utils/clock';


class Banner extends React.PureComponent {
    render() {
        return (
            <div className="market-banner">
                <div className="market-bg" >
                    <div className="market-banner-title" >
                        JESK MARKET
                    </div>
                    <div className="market-banner-slogan">
                        Right people, right service, right choice
                    </div>
                    <div style={{fontSize:"15px", fontWeight:'bold', marginTop:"35px", position:"absolute", right:'55px', bottom:'10px'}} ><Link style={{color:'white'}}> VIEW MORE <Icon type="right"/> </Link></div>
                </div>
            </div>
        );
    }
}

export default Banner;