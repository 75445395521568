import React from 'react';
import { Row, Col } from 'antd';
import panel1img from './img/safe.png';
import panel2img from './img/cctv.png';
import panel3img from './img/install.png';
import panel4img from './img/monitor.png';
// import button from './img/touch.png';
import { Link } from "react-router-dom";

class FourPanel extends React.Component {

    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{padding:'20px 0 10px 0', fontWeight:'bold', fontSize:'20px'}}>WE PROVIDE SERVICES</div>
                <Row  gutter={[4,4]}>
                    <Col className="tech-gutter-row" span={12}>
                        <div className="security-gutter-box">
                            <img className="tech-panel-icon" alt="TECHNICAL SUPPORT" src={panel1img}/>
                            <div className="tech-panel-title">COMMERCIAL SECURITY</div>
                            <div className="tech-panel-info" > Occaecat nulla exercitation amet culpa fugiat excepteur pariatur nisi. 
                            Anim enim aliquip aliquip non duis pariatur adipisicing cupidatat esse tempor occaecat adipisicing sit aute. Culpa ad non fugiat voluptate.</div>
                            <div style={{fontSize:'18px', textDecoration:"underline", padding:"15px", fontWeight:'bold'}}><Link style={{color:"#115599"}} >Learn More</Link></div>
                        </div>
                    </Col>
                    <Col className="tech-gutter-row" span={12}>
                        <div className="security-gutter-box">
                            <img className="tech-panel-icon" alt="NETWORK" src={panel2img}/>
                            <div className="tech-panel-title">VIDEO SURVEILLANCE </div>
                            <div className="tech-panel-info" > Occaecat nulla exercitation amet culpa fugiat excepteur pariatur nisi. 
                            Anim enim aliquip aliquip non duis pariatur adipisicing cupidatat esse tempor occaecat adipisicing sit aute. Culpa ad non fugiat voluptate.</div>
                            <div style={{fontSize:'18px', textDecoration:"underline", padding:"15px", fontWeight:'bold'}}><Link style={{color:"#115599"}} >Learn More</Link></div>
                
                        </div>
                    </Col>
                    <Col className="tech-gutter-row" span={12}>
                        <div className="security-gutter-box">
                            <img className="tech-panel-icon" alt="WEBSITE" src={panel3img}/>
                            <div className="tech-panel-title">PROFESSIONAL INSTALLATION</div>
                            <div className="tech-panel-info" > Occaecat nulla exercitation amet culpa fugiat excepteur pariatur nisi. 
                            Anim enim aliquip aliquip non duis pariatur adipisicing cupidatat esse tempor occaecat adipisicing sit aute. Culpa ad non fugiat voluptate.</div>
                            <div style={{fontSize:'18px', textDecoration:"underline", padding:"15px", fontWeight:'bold'}}><Link style={{color:"#115599"}} >Learn More</Link></div>
                        </div>
                    </Col>
                    <Col className="tech-gutter-row" span={12}>
                        <div className="security-gutter-box">
                            <img className="tech-panel-icon" alt="SYSTEM ARCHITECTURE" src={panel4img}/>
                            <div className="tech-panel-title">24/7 MONITORING</div>
                            <div className="tech-panel-info" > Occaecat nulla exercitation amet culpa fugiat excepteur pariatur nisi. 
                            Anim enim aliquip aliquip non duis pariatur adipisicing cupidatat esse tempor occaecat adipisicing sit aute. Culpa ad non fugiat voluptate.</div>
                            <div style={{fontSize:'18px', textDecoration:"underline", padding:"15px", fontWeight:'bold'}}><Link style={{color:"#115599"}} >Learn More</Link></div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default FourPanel;