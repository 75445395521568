import React from 'react';
import { Row, Col, Button} from 'antd';
import panel1img from './img/build.png';
import panel2img from './img/r-renovation.png';
import panel3img from './img/c-renovation.png';
import panel4img from './img/handyman.png';
import { Link } from "react-router-dom";

class Panels extends React.Component {

    render() {
        return (
            <div style={{ padding: '0 50px' }}>
                <Row style={{ textAlign: 'center' }}>
                    <Col className="construction-gutter-row" span={12}>
                        <div className="construction-gutter-txt">
                            <div className="construction-panel-title">RESIDENTIAL BUILDING</div>
                            <div className="construction-panel-info" > Occaecat nulla exercitation amet culpa fugiat excepteur pariatur nisi.
                            Anim enim aliquip aliquip non duis pariatur adipisicing cupidatat esse tempor occaecat adipisicing sit aute. Culpa ad non fugiat voluptate.
                            Labore dolore in non voluptate enim in ex proident id excepteur proident elit labore occaecat.</div>
                            <Link><Button className="construction-panel-btn" >GET IN TOUCH</Button></Link>
                        </div>
                    </Col>
                    <Col className="construction-gutter-row" span={12}>
                        <div className="construction-gutter-box" style={{ backgroundImage: `url(${panel1img})` }} />
                    </Col>

                    <Col className="construction-gutter-row" span={12}>
                        <div className="construction-gutter-box" style={{ backgroundImage: `url(${panel2img})` }} ></div>
                    </Col>
                    <Col className="construction-gutter-row" span={12}>
                        <div className="construction-gutter-txt">
                            <div className="construction-panel-title">SYSTEM ARCHITECTURE</div>
                            <div className="construction-panel-info" > Occaecat nulla exercitation amet culpa fugiat excepteur pariatur nisi.
                            Anim enim aliquip aliquip non duis pariatur adipisicing cupidatat esse tempor occaecat adipisicing sit aute. Culpa ad non fugiat voluptate.
                            Labore dolore in non voluptate enim in ex proident id excepteur proident elit labore occaecat.</div>
                            <Link><Button className="construction-panel-btn" >GET IN TOUCH</Button></Link>
                        </div>
                    </Col>

                    <Col className="construction-gutter-row" span={12}>
                        <div className="construction-gutter-txt">
                            <div className="construction-panel-title">RESIDENTIAL BUILDING</div>
                            <div className="construction-panel-info" > Occaecat nulla exercitation amet culpa fugiat excepteur pariatur nisi.
                            Anim enim aliquip aliquip non duis pariatur adipisicing cupidatat esse tempor occaecat adipisicing sit aute. Culpa ad non fugiat voluptate.
                            Labore dolore in non voluptate enim in ex proident id excepteur proident elit labore occaecat.</div>
                            <Link><Button className="construction-panel-btn" >GET IN TOUCH</Button></Link>
                        </div>
                    </Col>
                    <Col className="construction-gutter-row" span={12}>
                        <div className="construction-gutter-box" style={{ backgroundImage: `url(${panel3img})` }} />
                    </Col>

                    <Col className="construction-gutter-row" span={12}>
                        <div className="construction-gutter-box" style={{ backgroundImage: `url(${panel4img})` }} ></div>
                    </Col>
                    <Col className="construction-gutter-row" span={12}>
                        <div className="construction-gutter-txt">
                            <div className="construction-panel-title">SYSTEM ARCHITECTURE</div>
                            <div className="construction-panel-info" > Occaecat nulla exercitation amet culpa fugiat excepteur pariatur nisi.
                            Anim enim aliquip aliquip non duis pariatur adipisicing cupidatat esse tempor occaecat adipisicing sit aute. Culpa ad non fugiat voluptate.
                            Labore dolore in non voluptate enim in ex proident id excepteur proident elit labore occaecat.</div>
                            <Link><Button className="construction-panel-btn" >GET IN TOUCH</Button></Link>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Panels;