import React from 'react';
import { Row, Col, Button, Icon } from 'antd';
import panel1img from './img/panel1.png';
import panel2img from './img/panel2.png';
import panel3img from './img/panel3.png';
import panel4img from './img/panel4.png';

const technology = "TECHNOLOGY";
const construction = "CONSTRUCTION";
const security = "SECURITY";
const market = "MARKET";


class FourPanel extends React.Component {
     
    render() {
        return (
            <div className="panels">
                <div className="panel-title">
                    Multi-platform services
                </div>
                <Row gutter={8}>
                    <Col className="gutter-row" span={6}>
                        <div className="panel-box" style={{backgroundImage:`url(${panel1img})`}}>
                           <div className="panel-background" >
                                <div className="panel-subtitle">
                                    {technology}
                                </div>
                                <div className='pannel-content'>
                                    IMPACT c’est une équipe transdisciplinaire composée de professionnels des médias,
                                    de la pédagogie et de la communication.
                                    Leurs compétences sont mobilisées individuellement ou collectivement
                                </div>
                                <div className="pannel-button">
                                    <Button size="small" type="link">
                                        <span className="pannel-button-text">WARRANTY</span>
                                        <Icon type="right" style={{color:"#090909"}}/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div className="panel-box" style={{backgroundImage:`url(${panel2img})`}}>
                        <div className="panel-background" >
                            <div className="panel-subtitle">
                                {construction}
                            </div>
                            <div className='pannel-content'>
                            IMPACT c’est une équipe transdisciplinaire composée de professionnels des médias,
                                de la pédagogie et de la communication.
                                Leurs compétences sont mobilisées individuellement ou collectivement
                  
                            </div>
                            <div className="pannel-button">
                                <Button size="small" type="link">
                                    <span className="pannel-button-text">SUPPORT</span>
                                    <Icon type="right" style={{color:"#090909"}}/>
                                </Button>
                            </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div className="panel-box" style={{backgroundImage:`url(${panel3img})`}}>
                        <div className="panel-background" >
                            <div className="panel-subtitle">
                                {security}
                            </div>
                            <div className='pannel-content'>
                            IMPACT c’est une équipe transdisciplinaire composée de professionnels des médias,
                                de la pédagogie et de la communication.
                                Leurs compétences sont mobilisées individuellement ou collectivement
                            </div>
                            <div className="pannel-button">
                                <Button size="small" type="link">
                                    <span className="pannel-button-text">SERVICE</span>
                                    <Icon type="right" style={{color:"#090909"}}/>
                                </Button>
                            </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div className="panel-box" style={{backgroundImage:`url(${panel4img})`}}>
                        <div className="panel-background" >
                            <div className="panel-subtitle">
                                {market}
                            </div>
                            <div className='pannel-content'>
                            IMPACT c’est une équipe transdisciplinaire composée de professionnels des médias,
                                de la pédagogie et de la communication.
                                Leurs compétences sont mobilisées individuellement ou collectivement
                            </div>
                            <div className="pannel-button">
                                <Button size="small" type="link">
                                    <span className="pannel-button-text">TRACK & TRACE</span>
                                    <Icon type="right" style={{color:"#090909"}}/>
                                </Button>
                            </div>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default FourPanel;