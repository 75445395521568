import React from 'react';
import { Row, Col } from 'antd';
import panel1img from './img/support.png';
import panel2img from './img/network.png';
import panel3img from './img/website.png';
import panel4img from './img/system.png';
import button from './img/touch.png';
import { Link } from "react-router-dom";

class FourPanel extends React.Component {

    render() {
        return (
            <div >
                <Row style={{ textAlign: 'center' }} gutter={[4,4]}>
                    <Col className="tech-gutter-row" span={12}>
                        <div className="tech-gutter-box">
                            <img className="tech-panel-icon" alt="TECHNICAL SUPPORT" src={panel1img}/>
                            <div className="tech-panel-title">TECHNICAL SUPPORT</div>
                            <div className="tech-panel-info" > Occaecat nulla exercitation amet culpa fugiat excepteur pariatur nisi. 
                            Anim enim aliquip aliquip non duis pariatur adipisicing cupidatat esse tempor occaecat adipisicing sit aute. Culpa ad non fugiat voluptate.</div>
                            <Link><img className="tech-panel-btn" alt="button" src={button}/></Link>
                        </div>
                    </Col>
                    <Col className="tech-gutter-row" span={12}>
                        <div className="tech-gutter-box">
                            <img className="tech-panel-icon" alt="NETWORK" src={panel2img}/>
                            <div className="tech-panel-title">NETWORK</div>
                            <div className="tech-panel-info" > Occaecat nulla exercitation amet culpa fugiat excepteur pariatur nisi. 
                            Anim enim aliquip aliquip non duis pariatur adipisicing cupidatat esse tempor occaecat adipisicing sit aute. Culpa ad non fugiat voluptate.</div>
                            <Link><img className="tech-panel-btn" alt="button" src={button}/></Link>
                
                        </div>
                    </Col>
                    <Col className="tech-gutter-row" span={12}>
                        <div className="tech-gutter-box">
                            <img className="tech-panel-icon" alt="WEBSITE" src={panel3img}/>
                            <div className="tech-panel-title">WEBSITE</div>
                            <div className="tech-panel-info" > Occaecat nulla exercitation amet culpa fugiat excepteur pariatur nisi. 
                            Anim enim aliquip aliquip non duis pariatur adipisicing cupidatat esse tempor occaecat adipisicing sit aute. Culpa ad non fugiat voluptate.</div>
                            <Link><img className="tech-panel-btn" alt="button" src={button}/></Link>
                        </div>
                    </Col>
                    <Col className="tech-gutter-row" span={12}>
                        <div className="tech-gutter-box">
                            <img className="tech-panel-icon" alt="SYSTEM ARCHITECTURE" src={panel4img}/>
                            <div className="tech-panel-title">SYSTEM ARCHITECTURE</div>
                            <div className="tech-panel-info" > Occaecat nulla exercitation amet culpa fugiat excepteur pariatur nisi. 
                            Anim enim aliquip aliquip non duis pariatur adipisicing cupidatat esse tempor occaecat adipisicing sit aute. Culpa ad non fugiat voluptate.</div>
                            <Link><img className="tech-panel-btn" alt="button" src={button}/></Link>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default FourPanel;