import React from 'react';
import { Button, Icon } from 'antd';
// import Clock from '../utils/clock';


class Banner extends React.PureComponent {
    render() {
        return (
            // 2010 2月
            <div className="banner">
                <div className="banner-title">
                    JESKGroup
                </div>
                <div className="banner-slogan">
                    Right people, right service, right choice
                </div>
                <br/>
                <Button className="banner-button" style={{backgroundColor:"black", color:"white", border:'none',
                height:"36px", marginTop:"5px", fontWeight:"bold", fontSize:'medium', textAlign:'center'}}>
                    ABOUT US
                    <Icon type="right" />
                </Button>
                {/* <Clock/> */}
                <div className="banner-info">
                    &nbsp;We service locally since February, 2010 &nbsp;
                </div>
            </div>
        );
    }
}

export default Banner;