import React from 'react';
import { Layout, Menu } from 'antd';
import login_icon from './img/login.png';
import { Link } from "react-router-dom";

const { Header } = Layout;
const Technology = "TECHNOLOGY";
const Construction = "CONSTRUCTION";
const Security = "SECURITY";
const Market = "MARKET";
const ContactUs = "CONTACT US";

class Nav_bar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneOpen: undefined,
        };
    }

    render() {
        return (
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%', background: 'white' }}>
                <div style={{ padding: '0 50px 0 50px', borderBottom: "1px solid lightgrey", height: "55px" }}>
                    <Link href="/" to="/"> <div className="logo" style={{ color: 'black', fontWeight: 'bold', fontSize: '35px', fontFamily: 'impact' }}>JESK.COM.AU</div></Link>
                    <Menu
                        className="menu"
                        theme="light"
                        mode="horizontal"
                        // defaultSelectedKeys={['1']}
                        style={{ borderBottom: 'none', position: "absolute", right: '50px', top: '10px', background: 'transparent' }}
                    >
                        <Menu.Item key="1" className="menu-item" >
                            <Link href="/technology" to="/technology">
                                {Technology}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="2" className="menu-item">
                            <Link href="/construction" to="/construction">
                                {Construction}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="3" className="menu-item">
                            <Link href="/security" to="/security">
                                {Security}
                            </Link></Menu.Item>
                        <Menu.Item key="4" className="menu-item">
                            <Link href="/market" to="/market">
                                {Market}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="5" className="menu-item">
                             <Link href="/contact" to="/contact">
                                 {ContactUs}
                             </Link>
                        </Menu.Item>
                        <Menu.Item key="6" className="menu-item" ><img style={{ height: '35px', marginBottom: '5px' }} src={login_icon} alt="login_icon" /></Menu.Item>
                    </Menu>
                </div>
            </Header>
        );
    }
}





export default Nav_bar;