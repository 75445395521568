import React from 'react';
import { Button, Icon } from 'antd';
// import Clock from '../utils/clock';


class Banner extends React.PureComponent {
    render() {
        return (
            // 2010 2月
            <div className="security-banner">
                <div className="tech-banner-title">
                    JESK SECURITY
                </div>
                <div className="tech-banner-slogan">
                    Right people, right service, right choice
                </div>
                <br/>
                <Button className="tech-banner-button" style={{backgroundColor:"black", color:"white", border:'none',
                height:"36px", marginTop:"5px", fontWeight:"bold", fontSize:'medium', textAlign:'center'}}>
                    KNOW MORE
                    <Icon type="right" />
                </Button>
                {/* <Clock/> */}
                {/* <div className="tech-banner-info">
                    &nbsp;We service locally since February, 2010 &nbsp;
                </div> */}
            </div>
        );
    }
}

export default Banner;