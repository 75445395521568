import React from 'react';
import { Layout } from 'antd';
import Banner from './banner';
import Products from './products';
const { Content } = Layout;

export default class Market extends React.Component {

    render() {
        return (
            <Content style={{ padding: '50px 0' }}>
              <Banner/>
              <Products/>
            </Content>
        );
    }

}