import React from 'react';
import { Layout } from 'antd';
import Banner from './banner';
import Panels from './panels';
import MailBox from './mail_box';
const { Content } = Layout;

export default class Security extends React.Component {

    render() {
        return (
            <Content style={{ padding: '50px 0', background:"#F4F4F4" }}>
               <Banner/>
               <Panels/>
               <MailBox/>
            </Content>
        );
    }

}