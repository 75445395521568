import React from 'react';
import { Row, Col } from 'antd';
import pro from './img/professional.png';
import commitment from './img/commitment.png';
import honesty from './img/honesty.png';

export default class Annocement extends React.Component {
    render() {
        return (
            <div style={{ padding: '0 50px', textAlign: "center" }}>
                <Row gutter={8}>

                    <Col className="gutter-row" span={8}>
                        <div className="construction-anno-box">
                            <img className="construction-anno-img" alt="professional" src={pro} />
                            <div className="construction-anno-title">PROFESSIONAL</div>
                            <div className="construction-anno-info">Ullamco consequat mollit tempor velit et nostrud magna. Ad nulla laborum laboris adipisicing reprehenderit est. Dolore enim ut ut in do magna aliquip esse culpa ipsum duis. </div>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <div className="construction-anno-box">
                            <img className="construction-anno-img" alt="professional" src={commitment} />
                            <div className="construction-anno-title">PROFESSIONAL</div>
                            <div className="construction-anno-info">Ullamco consequat mollit tempor velit et nostrud magna. Ad nulla laborum laboris adipisicing reprehenderit est. Dolore enim ut ut in do magna aliquip esse culpa ipsum duis. </div>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <div className="construction-anno-box">
                            <img className="construction-anno-img" alt="professional" src={honesty} />
                            <div className="construction-anno-title">PROFESSIONAL</div>
                            <div className="construction-anno-info">Ullamco consequat mollit tempor velit et nostrud magna. Ad nulla laborum laboris adipisicing reprehenderit est. Dolore enim ut ut in do magna aliquip esse culpa ipsum duis. </div>
                        </div>
                    </Col>
                </Row>

            </div>


        );
    }
}